/* eslint-disable no-undef */

import React, { Component } from 'react';
import { withAuthenticator } from 'aws-amplify-react';
import Theme from 'Theme';
import '@kaspien/react-foundation/dist/react-foundation.min.css';
import 'Amplify';
import { I18n } from 'aws-amplify';

const authScreenLabels = {
  en: {
    'Sign In': 'Log In',
    Username: 'Email',
    Password: 'Password',
    'Reset password': 'Forgot Password',
  },
};

I18n.setLanguage('en');
I18n.putVocabularies(authScreenLabels);

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectUrl: process.env.REACT_APP_POST_AUTH_URL,
    };
  }

  componentDidMount() {
    const { search } = window.location;
    const values = new URLSearchParams(search);
    const redirectUrl = values.get('redirectUrl');
    if (redirectUrl) {
      this.setState({
        redirectUrl,
      });
    }
  }

  render() {
    const { redirectUrl } = this.state;
    window.location.href = redirectUrl;

    return (<div />);
  }
}

export default withAuthenticator(Login, {
  theme: Theme,
});
