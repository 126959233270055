import Amplify from 'aws-amplify';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_REGION || '',
    userPoolId: process.env.REACT_APP_USERPOOL_ID || '',
    userPoolWebClientId: process.env.REACT_APP_WEB_CLIENT_ID || '',
    cookieStorage: {
      domain: process.env.REACT_APP_COOKIE_DOMAIN || 'localhost',
      path: '/',
      expires: 365,
      secure: process.env.REACT_APP_COOKIE_SECURITY === 'true',
    },
  },
});
