/**
 * Sample Theme at https://github.com/aws-amplify/amplify-js/blob/master/packages/aws-amplify-react/src/Amplify-UI/Amplify-UI-Theme-Sample.tsx
 */

export const Container = {
  fontFamily: 'Lato',
  height: '100vh',

};

export const FormContainer = {
  background: '#F5FCFF',
  boxSizing: 'border-box',
  height: '100%',
  margin: 'auto',
  paddingTop: '5%',
};

export const FormSection = {
  border: '1px solid #ffffff',
  borderRadius: '15px 0px 15px 0px',
  boxShadow: '0px 4px rgba(0, 0, 0, .25)',
};

export const SectionHeader = {
  display: 'none',
};

export const SectionFooter = {
  width: '100%',
};

export const InputLabel = {
  color: 'hsl(0, 0%, 29%)',
};

export const Input = {
  border: '1px solid #101010',
  borderRadius: '15px 0px 15px 0px',
  color: 'hsl(0, 0%, 29%)',
  fontFamily: 'Lato',
  outline: '0',
  padding: '10px',
};

export const Button = {
  backgroundColor: 'hsl(196, 90%, 59%)',
  border: 'O',
  borderRadius: '15px 0px 15px 0px',
  height: '2.5rem',
  letterSpacing: '1px',
  lineHeight: 'initial',
  padding: '0',
  widht: '100%',
};

export const A = {
  color: 'hsl(215, 100%, 25%)',
  cursor: 'pointer',
};

const Bootstrap = {
  container: Container,
  formContainer: FormContainer,
  formSection: FormSection,
  sectionHeader: SectionHeader,
  sectionFooter: SectionFooter,
  inputLabel: InputLabel,
  input: Input,
  button: Button,
  a: A,
};

export default Bootstrap;
