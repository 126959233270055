/* eslint-disable no-undef */

import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react';
import Theme from 'Theme';
import '@kaspien/react-foundation/dist/react-foundation.min.css';
import 'Amplify';

class Refresh extends Component {
  componentDidMount() {
    const { search } = window.location;

    const values = new URLSearchParams(search);
    const redirectUrl = values.get('redirectUrl');

    Auth.currentAuthenticatedUser({
      bypassCache: true,
    })
      .then(() => {
        window.location.href = redirectUrl;
      })
      .catch((error) => {
        console.error('Refresh error: ', error);
      });
  }

  render() {
    return (<div />);
  }
}

export default withAuthenticator(Refresh, {
  theme: Theme,
});
