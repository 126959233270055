import React, { Component } from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';
import {
  Core,
  NotFound,
  Page,
} from '@kaspien/react-foundation';
// Routes
import Login from './Login';
import Refresh from './Refresh';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Page>
          <Core>
            <Switch>
              {/* Default */}
              <Route exact path="/" component={Login} />
              {/* Refresh */}
              <Route exact path="/refresh" component={Refresh} />
              <Route component={NotFound} />
            </Switch>
          </Core>
        </Page>
      </BrowserRouter>
    );
  }
}

export default App;
